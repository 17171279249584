<template>
    <div>
        <Drawer
            v-model="drawer_trans"
            width="450"
            :mask-closable="true"
            :transfer="true"
        >
            <playerTransEdit :sport_id="sport_id" :player_id="trans_id" @closePage="closeTransPage"></playerTransEdit>
        </Drawer> 

        <Drawer
            v-model="drawer_trans_short"
            width="450"
            :mask-closable="true"
            :transfer="true"
        >
            <playerShortTransEdit :sport_id="sport_id" :player_id="trans_id" @closePage="closeTransPage"></playerShortTransEdit>
        </Drawer> 

        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
            <Form label-position="left" :label-width="80" >
                <FormItem label="id: " v-if="data.id">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.id" disabled></Input>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="名称: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.name" :class="locks[String(type)+'-1-'+'name'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'name']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_id" :type="type" :sub_type="1" _key="name"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="简称: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.short_name" :class="locks[String(type)+'-1-'+'short_name'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'short_name']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_id" :type="type" :sub_type="1" _key="short_name"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="logo: ">
                    <Row>
                        <imgEditor v-model="data.logo" :prefix="$conf.ballsports.img_prefix" :dir="sport_id===6? 'baseball/player': (sport_id===10 ? 'volleyball/player': 'v2/player')" 
                            :previewButton='true' :clearButton='true' cdn='ballsports'>
                        </imgEditor>
                        <!-- <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'logo']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_id" :type="type" :sub_type="1" _key="logo"></Lock>
                        </Col> -->
                    </Row>
                </FormItem>
                <FormItem label="队伍: ">
                    <Row>
                        <Col span="20" >
                            <teamSearch v-model="data.team" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'team_id'] === 1? 'locked-input': '' "></teamSearch>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'team_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_id" :type="type" :sub_type="1" _key="team_id"></Lock>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="生日: " >
                    <Row>
                        <Col span="20" >
                            <DatePicker 
                                type="datetime" 
                                format="yyyy-MM-dd" 
                                :class="locks[String(type) + '-1-' + 'birthday'] === 1? 'locked-input': '' "
                                style="width: 100%"
                                v-model="birthday"
                            >
                            </DatePicker>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'birthday']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_id" :type="type" :sub_type="1" _key="birthday"></Lock>
                        </Col>
                    </Row>
                </FormItem> 
                
                <FormItem label="身高: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.height" :class="locks[String(type)+'-1-'+'height'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'height']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_id" :type="type" :sub_type="1" _key="height"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="体重: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.weight" :class="locks[String(type)+'-1-'+'weight'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'weight']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_id" :type="type" :sub_type="1" _key="weight"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <!-- <FormItem label="地面: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.ground_id" :class="locks[String(type) + '-1-' + 'ground_id'] === 1? 'locked-select': '' ">
                                <Option v-for="item in $conf.ballsports.grounds" :value="item.id" :key="item.id">{{ item.name }}</Option>
                            </Select>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-' + 'ground_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_id" :type="type" :sub_type="1" _key="ground_id"></Lock>
                        </Col>
                    </Row>
                </FormItem> -->
                <FormItem label="是否删除: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.deleted" :class="locks[String(type) + '-1-' + 'deleted'] === 1? 'locked-select': '' ">
                                <Option :value="1" key="1">是</Option>
                                <Option :value="0" key="0">否</Option>
                            </Select>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'deleted']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_id" :type="type" :sub_type="1" _key="deleted"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="全称翻译: ">
                    <Row>
                        <Col span="20" >
                            <Button type="default" long @click="editTrans">翻译</Button>
                        </Col>
                    </Row>
                </FormItem>
                
                <FormItem label="简称翻译: ">
                    <Row>
                        <Col span="20" >
                            <Button type="default" long @click="editShortTrans">翻译</Button>
                        </Col>
                    </Row>
                </FormItem>
            </Form>
            </TabPane>
            <!-- <TabPane label="额外信息" name="2">
                <Form label-position="left" :label-width="80">
                </Form>
            </TabPane> -->
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import teamSearch from '../team/search.vue';
import Lock from '../lock.vue';
import { getPlayer, updatePlayer } from '@/api/ballsports/base/player';
import moment from 'moment';
import imgEditor from '@/components/img-editor/img-editor.vue';
import playerTransEdit from './trans/edit.vue';
import playerShortTransEdit from './trans_short/edit.vue';

export default {
    name: "player-edit",
    props: {
        sport_id: Number,   // 运动id
        player_id: Number,   // 联赛id
    },
    components: {
        Lock,
        teamSearch,
        imgEditor,
        playerTransEdit,
        playerShortTransEdit,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 6,
            type: 1, 
            spinShow: false,
            data: {
                id: 0,
                name:'',
                "team": {},
                deleted: 0,
                sport_id: self.sport_id,
            },
            locks: {},
            drawer_trans: false,
            trans_id: 0,
            drawer_trans_short: false,
        }
    },
    methods: {
        // 获取联赛分类
        getData () {
            let self = this;
            if (self.player_id && self.player_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: self.sport_id,
                    "id" : self.player_id,
                }
                getPlayer( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                            self.locks = response.data.data.locks ;
                        }else{
                            this.data = {}
                            self.locks = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                // alert(1)
                // self.data = {
                //     id: 0,
                //     name:'',
                //     "team": {},
                //     deleted: 0,
                //     sport_id: self.sport_id,
                // }
                self.locks = {}
            }
            
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        save() {
            let self = this;
            let params = {
                id: self.data.id,
                sport_id: self.data.sport_id || self.sport_id,
                team_id: self.data.team.id,
                name: self.data.name,
                short_name: self.data.short_name,
                logo: self.data.logo,
                birthday: self.data.birthday,
                height: self.data.height,
                weight: self.data.weight,
                deleted: self.data.deleted,
            }
            updatePlayer(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end
        editTrans(){
            let self = this;
            self.drawer_trans_short = false;
            self.drawer_trans = true;
            self.trans_id = self.player_id;
        },
        editShortTrans(){
            let self = this;
            self.drawer_trans = false;
            self.drawer_trans_short = true;
            self.trans_id = self.player_id;
        },
        // end 
        closeTransPage(){
            let self = this;
            self.drawer_trans = false;
            self.drawer_trans_short = false;
            self.trans_id = 0;
        },
        // end
    },
    mounted () {
        // let self = this;
    },
    watch: {
        "player_id":{
            handler(value){
                // console.log( value );
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    self.getData();
                };
            },
	        immediate: true,  // 深度触发
        }
    },
    computed: {
        birthday: {
            get: function () {
                return moment.unix(this.data.birthday).toDate();
            },
            set: function (newValue) {
                this.data.birthday = parseInt( moment(newValue).unix() );
            }
        },
        
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>