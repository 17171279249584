<template>
    <Row>
        <Col span="6">
            <Select v-model="source_id" @on-change="changeSourceId">
                <!-- <Option :value="0" :key="0">全部</Option> -->
                <Option v-for="item in sources" :value="item.id" :key="item.id" >{{ item.name }}</Option>
            </Select>
        </Col>
        <Col span="18">
            <AutoComplete
                v-model="search_key"
                placeholder="搜索"
                @on-change = "doSearch"
                @on-blur  = 'blur'
                :class="classname"
            >
                <Option v-for="option in datas" :value="option.name" :tag="option.id" :key="option.id" @click.native="onClicked(option)">
                    {{ option.id }}: {{ option.name }}
                </Option>
            </AutoComplete>
        </Col>
    </Row>
</template>

<script>

import { getPlayerSources } from '@/api/ballsports/base/player';

export default {
    name: "third-player-search",
    props: {
        value   : Object,    // {id, name, logo}
        sport_id: 0,
        classname : String,
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            search_key: '',
            data: {
                'id': 0,
                'name': '',
                'logo': '',
            },
            datas: {},
            loading: false,
            source_id: '',
            sources: {},
        }
    },
    methods: {
        // 获取源列表
        getSources(){
            let self = this;
            let params = {
                sport_id: self.sport_id,
            }
            getPlayerSources(params).then(response => {
                if (response.data.code === 0){
                    self.sources = response.data.data;
                }else{
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end 
        doSearch (value) {
            let self = this;
            // console.log("PLAYER SEARCH:", value, self.data.name);
            if (value && (value !== self.data.name)){
                self.loading = true;
                self.datas = {
                    38: {
                        "id": 38,
                        "name": "demo38",
                        "logo": ""
                    },
                    2: {
                        "id": 2,
                        "name": "demo2",
                        "logo": ""
                    },
                }
                self.loading = false;
            }else if (value && ( value === self.data.name)){
                self.datas = {}
                self.datas [self.data.id]= self.data
            }
        },
        // end
        onClicked(data){
            // console.log("onClicked(option)", option)
            let self = this;
            self.data = data;
            self._emit();
            self.search_key = self.data.name;
        },
        // end
        _emit(){
            this.$emit('input', this.data);
        },
        // end
        blur(){
            // 失去焦点时触发
            let self = this;
            self.search_key = self.data.name;
        },
        // end
        changeSourceId(){
            // 源id更该变更之后 
            this.$emit('onSourceIdChange', this.source_id, this.sources[this.source_id].name );
        },
        // end
    },
    mounted () {
        // console.log( this.sport_id, this.data )
        // 根据sport_id 获取sources
        // alert( this.sport_id );
        this.getSources();
    },
    watch: {
        'value': {
            handler: function(value){
                this.data = value;
                this.search_key = value.name;
            },
            deep: true,
        },
        // end 
    },
    computed: {
    }
}
</script>
<style scoped>
    /* @import "search.css" */
</style>