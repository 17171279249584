import http from '@/utils/http';

// 获取选手列表
export const getPlayer = (params) => {
    return http.request({
        url: '/v1/ballsports/base/player/list',
        method: 'get',
        params: params
    })
}

// 新增选手的关联
export const addPlayerRelation = (params) => {
    return http.request({
        url: '/v1/ballsports/base/player/relation/add',
        method: 'post',
        data: params
    })
}

// 删除选手的关联
export const delPlayerRelation = (params) => {
    return http.request({
        url: '/v1/ballsports/base/player/relation/del',
        method: 'post',
        data: params
    })
}

// 获取选手的所有源
export const getPlayerSources = (params) => {
    return http.request({
        url: '/v1/ballsports/base/player/source/list',
        method: 'get',
        params: params
    })
}

// // 获取选手分类列表
// export const getPlayerCategory = (params) => {
//     return http.request({
//         url: '/v1/ballsports/base/player/category/list',
//         method: 'get',
//         params: params
//     })
// }

// 搜索
export const searchPlayer = (params) => {
    return http.request({
        url: '/v1/ballsports/base/player/search',
        method: 'post',
        data: params
    })
}

// 更新比赛
export const updatePlayer = (params) => {
    return http.request({
        url: "/v1/ballsports/base/player/update",
        method: 'post',
        data: params,
    })
}