import http from '@/utils/http';

// 获取
export const getPlayerTrans = (params) => {
    return http.request({
        url: '/v1/ballsports/base/player/trans/get',
        method: 'get',
        params: params
    })
}

// 更新
export const updatePlayerTrans = (params) => {
    return http.request({
        url: "/v1/ballsports/base/player/trans/update",
        method: 'post',
        data: params,
    })
}

export const getPlayerShortTrans = (params) => {
    return http.request({
        url: '/v1/ballsports/base/player/trans_short/get',
        method: 'get',
        params: params
    })
}

// 更新
export const updatePlayerShortTrans = (params) => {
    return http.request({
        url: "/v1/ballsports/base/player/trans_short/update",
        method: 'post',
        data: params,
    })
}
